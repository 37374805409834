import React from 'react';
import { observer } from 'mobx-react';
import classnames from 'classnames';
import { Trans } from 'react-i18next';
import { Button } from 'antd';
import { AiOutlineUnorderedList } from 'react-icons/ai';
import styles from './NoChatsFound.module.scss';
const NoChatsFound = ({ onClearAllFilters }) => {
    return (React.createElement("div", { className: styles.blockerContainer },
        React.createElement("div", { className: styles.blockerInnerContainer },
            React.createElement(AiOutlineUnorderedList, { className: styles.blockerImage }),
            React.createElement("p", { className: styles.blockerTitle },
                React.createElement(Trans, { i18nKey: "noChatsFound" })),
            React.createElement("p", { className: styles.blockerText },
                React.createElement(Trans, { i18nKey: "noChatsFoundDescription" })),
            onClearAllFilters && (React.createElement(Button, { className: classnames(styles.blockerButton, 'white-button'), onClick: onClearAllFilters },
                React.createElement(Trans, { i18nKey: "clearAllFilters" }))))));
};
export default observer(NoChatsFound);
