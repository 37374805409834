import React, { createRef, useState } from 'react';
import { observer } from 'mobx-react';
import classNames from 'classnames';
import { Button } from 'antd';
import { MoreOutlined } from '@ant-design/icons';
import { MdFilterList, MdFilterListOff } from 'react-icons/md';
import { pick } from 'lodash';
import { Trans } from 'react-i18next';
import ConversationSearch from '../ConversationSearch';
import DepartmentsFilter from '../DepartmentsFilter';
import ChannelSelect from './ChannelSelect';
import AgentsFilter from '../AgentsFilter';
import { SEARCH_MIN_QUERY } from '../../../constants';
import { DropdownMenuList, dropdownMenu } from './DropdownMenu';
import { useStore } from '../../../store/rootStore';
import useCheckIfMobile from '../../../helpers/hooks/useCheckIfMobile';
import s from './ChatsList.module.scss';
const Filters = ({ agents, departments, resolve, onSearch, loading }) => {
    const [dropdownOption, setDropdownOption] = useState(null);
    const { channels, chatsStore } = useStore();
    const isMobile = useCheckIfMobile();
    const containerRef = createRef();
    const showChannelSelect = channels.accounts.length > 1;
    const filtersApplied = Object.values(pick(chatsStore.searchFilter, ['agent', 'department'])).filter(Boolean).length;
    return (React.createElement("div", { className: classNames(s.filtersContainer, {
            [s.withBorder]: !showChannelSelect && !isMobile,
        }), ref: containerRef },
        React.createElement(ConversationSearch, { minQuery: SEARCH_MIN_QUERY, onSearch: onSearch, dropdownIcon: isMobile ? MdFilterList : MoreOutlined, channelSelect: React.createElement(ChannelSelect, { accounts: channels.accounts, activeChannel: chatsStore.channel, onChannelClick: chatsStore.setChannel, className: s.channelSelectMobile, size: "large", showBorders: true }), dropdownMenu: dropdownMenu({
                resolve,
                setDropdownOption,
                filtersApplied,
                agents: React.createElement(AgentsFilter, { fullwidth: true }),
                departments: React.createElement(DepartmentsFilter, { fullWidth: true }),
                clearAllFiltersButton: (React.createElement(Button, { onClick: chatsStore.resetFilters, type: "default", icon: React.createElement(MdFilterListOff, null), className: s.clearAllButton, danger: true },
                    React.createElement(Trans, { i18nKey: "clearAllFilters" }))),
            }), loading: loading, isMobile: isMobile, filtersApplied: filtersApplied }),
        !isMobile && (React.createElement(React.Fragment, null,
            showChannelSelect && (React.createElement(ChannelSelect, { accounts: channels.accounts, activeChannel: chatsStore.channel, onChannelClick: chatsStore.setChannel })),
            (agents || departments) && (React.createElement("div", { className: s.filtersBlock },
                departments && React.createElement(DepartmentsFilter, { fullWidth: !agents }),
                agents && React.createElement(AgentsFilter, { fullwidth: !departments }))),
            React.createElement(DropdownMenuList, { dropdownOption: dropdownOption, setDropdownOption: setDropdownOption, containerRef: containerRef })))));
};
export default observer(Filters);
