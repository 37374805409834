import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { Trans, withTranslation } from 'react-i18next';
import classnames from 'classnames';
import { CHATS_GROUP, ChatsLoading } from '../../../store/ChatsStore';
import styles from './ChatsList.module.scss';
import { withStore } from '../../../store/rootStore';
import Filters from './Filters';
import List from './List';
import { MessagesSearchLoading } from '../../../store/MessagesStore';
import MessagesSearchList from './MessagesSearchList';
import FavoriteChats from './FavoriteChats';
import NoChatsFound from '../NoChatsFound';
/** Show max this count of found chats (if any), when messages found */
const maxChatsWithMessagesFound = 10;
class ChatsList extends Component {
    constructor() {
        super(...arguments);
        Object.defineProperty(this, "isAgentsFilterAvailable", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: () => {
                const { agentsStore } = this.props.store;
                return agentsStore.users.length > 0;
            }
        });
        Object.defineProperty(this, "isDepartmentsFilterAvailable", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: () => {
                const { departmentsStore } = this.props.store;
                return departmentsStore.departments.length > 0;
            }
        });
        Object.defineProperty(this, "isResolveAvailable", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: () => {
                const { chatsStore } = this.props.store;
                const groupAllowed = chatsStore.currentGroup === CHATS_GROUP.PENDING || chatsStore.currentGroup === CHATS_GROUP.ASSIGNED;
                return groupAllowed;
            }
        });
        Object.defineProperty(this, "renderResults", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: () => {
                const { chatsStore, messages: messagesStore, activeChatStore } = this.props.store, { chatsLoading, chats, chatsHasMore, searchFilter, currentGroup, currentGroupStatus, favoriteChats, hasFilterApplied } = chatsStore, { searchResults, search, searchLoading, searchHasMore } = messagesStore, agentsFilter = this.isAgentsFilterAvailable(), departmentsFilter = this.isDepartmentsFilterAvailable(), resolveBtn = this.isResolveAvailable(), longList = !resolveBtn && !agentsFilter && !departmentsFilter;
                const ChatsList = (renderChats, className, embed = false, header, favorites) => {
                    return (React.createElement(List, { id: "chatsList", key: "chats-list", className: className, search: searchFilter.text || '', chats: renderChats, loading: chatsLoading, hasMore: !embed ? chatsHasMore : false, onLoadMore: () => chatsStore.loadMore(), onClickChat: (chat) => activeChatStore.setActiveChat(chat), header: header, sectionHeaders: !embed ? this.getChatsHeaders(currentGroup) : [], favorites: favorites }));
                };
                const header = [];
                const showFavorites = favoriteChats.length && !hasFilterApplied;
                if (showFavorites) {
                    header.push(React.createElement(FavoriteChats, { key: "favorite-chats", chats: [...favoriteChats], status: currentGroupStatus, onClickChat: (chat) => activeChatStore.setActiveChat(chat) }));
                    /* If All Chats tab with showFavorites, separate favorites with a header */
                    if (typeof currentGroupStatus === 'undefined') {
                        header.push(React.createElement(Title, { key: "allChats", text: "allChats" }));
                    }
                }
                if (searchResults.length) {
                    if (chats.length) {
                        header.push(React.createElement("h2", { key: "chats-header", className: styles.resultsListSearchSectionTitle },
                            React.createElement(Trans, { i18nKey: "chats" })), ChatsList(chats.slice(0, maxChatsWithMessagesFound), undefined, true));
                    }
                    header.push(React.createElement("h2", { key: "messages-header", className: styles.resultsListSearchSectionTitle },
                        React.createElement(Trans, { i18nKey: "messages" })));
                    return (React.createElement(React.Fragment, null,
                        React.createElement(MessagesSearchList, { className: classnames(styles.resultsList, { [styles.resultsListLong]: longList }), header: header, search: search || '', results: searchResults, loading: searchLoading, hasMore: searchHasMore || false, onLoadMore: () => messagesStore.loadSearchResults(), onClickMessage: (messageId, chatId) => activeChatStore.setActiveChatId(chatId, messageId) })));
                }
                return ChatsList(chats, classnames(styles.resultsList, {
                    [styles.resultsListLong]: longList,
                    [styles.hideFavorites]: showFavorites,
                }), false, header, favoriteChats.map((chat) => chat._id));
            }
        });
    }
    render() {
        const { chatsStore, messages: messagesStore } = this.props.store, { chatsLoading, chats, resetFilters } = chatsStore, { searchLoading } = messagesStore, agentsFilter = this.isAgentsFilterAvailable(), departmentsFilter = this.isDepartmentsFilterAvailable(), resolveBtn = this.isResolveAvailable();
        return (React.createElement(React.Fragment, null,
            React.createElement(Filters, { loading: chatsLoading !== ChatsLoading.NONE || searchLoading !== MessagesSearchLoading.NONE, onSearch: (value, inMessages) => {
                    chatsStore.setSearchQuery(value);
                    if (inMessages) {
                        messagesStore.setSearch(value);
                    }
                    else if (messagesStore.search) {
                        messagesStore.setSearch(null);
                    }
                }, resolve: resolveBtn, agents: agentsFilter, departments: departmentsFilter }),
            chats.length === 0 ? (React.createElement(NoChatsFound, { onClearAllFilters: resetFilters })) : (this.renderResults())));
    }
    getChatsHeaders(group) {
        var _a;
        const uid = ((_a = this.props.store.loginStore.userDetails) === null || _a === void 0 ? void 0 : _a.uid) || null;
        if (group === CHATS_GROUP.PENDING || group === CHATS_GROUP.ASSIGNED) {
            const isPending = group === CHATS_GROUP.PENDING;
            return [
                {
                    content: React.createElement(Title, { key: "for-me", text: isPending ? 'chatListPendingForMeHeader' : 'chatListTakenByMeHeader' }),
                    isBefore: (chat) => {
                        return !!(chat.agent && chat.agent.uid && chat.agent.uid === uid);
                    },
                    once: true,
                },
                {
                    content: React.createElement(Title, { key: "for-others", text: isPending ? 'chatListAllOthersPendingHeader' : 'chatListAllOthersHeader' }),
                    isBefore: (chat) => {
                        return !(chat.agent && chat.agent.uid && chat.agent.uid === uid);
                    },
                    once: true,
                },
            ];
        }
        else if (group === CHATS_GROUP.BULK) {
            return [
                {
                    content: React.createElement(Title, { key: "bulk-by-me", text: "chatListBulkSentByMeHeader" }),
                    isBefore: (chat) => {
                        return !!(chat.sendingBulkAgent && chat.sendingBulkAgent === uid);
                    },
                    once: true,
                },
                {
                    content: React.createElement(Title, { key: "bulk-by-others", text: "chatListBulkSentByOthersHeader" }),
                    isBefore: (chat) => {
                        return !(chat.sendingBulkAgent && chat.sendingBulkAgent === uid);
                    },
                    once: true,
                },
            ];
        }
        return [];
    }
}
function Title({ text }) {
    return (React.createElement("h2", { className: styles.resultsListTitle },
        React.createElement(Trans, { i18nKey: text })));
}
export default withTranslation()(withStore(observer(ChatsList)));
