import React, { useEffect, useState } from 'react';
import { Button, Checkbox, Select, message } from 'antd';
import { Trans, useTranslation } from 'react-i18next';
import { observer } from 'mobx-react';
import Icon, { EditOutlined, LoadingOutlined } from '@ant-design/icons';
import classnames from 'classnames';
import { useHistory } from 'react-router-dom';
import { isArray, omit } from 'lodash';
import { MessagingChannelFeature } from '@whatsper/texterchat-common';
import { MySelect, MyInput } from '../../Base/Form';
import { channelThemePresets } from '../../../store/ChannelsStore/ChannelsStore';
import { useStore } from '../../../store/rootStore';
import { useStore as useBotAdminStore } from '../../../store/BotAdminStore';
import FeaturesGate from '../../FeaturesGate';
import validator from '@rjsf/validator-ajv8';
import { Form as RJSFForm } from '@rjsf/antd';
import s from './ChannelsForm.module.scss';
const ChannelsForm = ({ error, entity, onInput, getChannelAccount, formRef }) => {
    const { t } = useTranslation();
    const store = useStore();
    const botAdminStore = useBotAdminStore();
    const history = useHistory();
    const [activeAdapter, setActiveAdapter] = useState(store.channels.adapters[0] || null);
    const [formData, setFormData] = useState({
        isDefault: false,
        bot: null,
        themeId: undefined,
        adapterName: undefined,
        credentials: null,
        botDisable: false,
    });
    const [accountId, setAccountId] = useState(null);
    const [updateCredentials, setUpdateCredentials] = useState(false);
    const bots = [...botAdminStore.bots].map(([_, bot]) => bot);
    const channelThemes = activeAdapter
        ? Object.values((channelThemePresets === null || channelThemePresets === void 0 ? void 0 : channelThemePresets[activeAdapter.channel]) || {})
        : [];
    const { connectOrDisconnectWebook, webhookLoading, webhookMessage, webhookError, resetWebhookState, themesIdsInUse, currentAccount, } = store.channels;
    const onEditBusinessProfile = () => {
        store.navigation.toggleEditProfileDrawer();
        history.push('/');
    };
    const onChange = (name, value) => {
        setFormData((formData) => (Object.assign(Object.assign({}, formData), { [name]: value })));
    };
    const onBotChange = (id) => {
        if (!id) {
            onChange('bot', null);
        }
        else {
            onChange('bot', id);
        }
    };
    const setAdapter = (adapterName) => {
        const activeAdapter = store.channels.adapters.find((adapter) => adapter.name === adapterName);
        if (activeAdapter) {
            setActiveAdapter(activeAdapter);
        }
    };
    const onConnectOrDisconnectWebhook = (disconnect) => {
        if (entity) {
            connectOrDisconnectWebook(entity, disconnect);
        }
    };
    useEffect(() => {
        if (entity) {
            resetWebhookState();
            getChannelAccount(entity.accountId, entity.channel);
            setFormData((formData) => {
                var _a, _b;
                return (Object.assign(Object.assign({}, formData), { title: entity.title || ((_a = entity.metadata) === null || _a === void 0 ? void 0 : _a.accountTitle) || entity.accountId, isDefault: entity.isDefault, themeId: (_b = store.channels.themes[entity.accountId]) === null || _b === void 0 ? void 0 : _b.id, adapterName: entity.adapterName, bot: entity.bot, botDisable: entity.botDisable }));
            });
            setAccountId(entity.accountId);
        }
    }, [entity]);
    useEffect(() => {
        if (formData.adapterName) {
            setAdapter(formData.adapterName);
        }
    }, [formData.adapterName]);
    useEffect(() => {
        if (currentAccount && currentAccount.credentials) {
            setFormData((formData) => (Object.assign(Object.assign({}, formData), { credentials: currentAccount.credentials })));
        }
    }, [currentAccount]);
    useEffect(() => {
        if (entity && !entity.bot && entity.bot !== null) {
            const defaultBot = bots.find((bot) => bot.default);
            onChange('bot', defaultBot === null || defaultBot === void 0 ? void 0 : defaultBot.identifier);
        }
    }, [bots.length]);
    useEffect(() => {
        onInput(Object.assign(Object.assign(Object.assign({}, omit(formData, ['accountId', 'credentials'])), (!entity || updateCredentials ? { credentials: formData.credentials } : {})), (!entity && accountId ? { accountId } : {})));
    }, [formData]);
    if (!activeAdapter) {
        return React.createElement(LoadingOutlined, null);
    }
    return (React.createElement("div", { className: s.container },
        error && React.createElement("div", { className: classnames(s.labelContainer, s.error) }, error),
        React.createElement("div", { className: s.labelContainer },
            React.createElement("label", null,
                React.createElement(Trans, { i18nKey: "provider" })),
            React.createElement(MySelect, { className: s.formNameInput, value: formData.adapterName, onChange: (adapterName) => onChange('adapterName', adapterName), size: "large", disabled: !!entity }, store.channels.adapters.map((adapter) => (React.createElement(Select.Option, { value: adapter.name, key: adapter.name }, adapter.title || adapter.name))))),
        formData.adapterName && (React.createElement(React.Fragment, null,
            React.createElement("div", { className: s.labelContainer },
                React.createElement("label", null,
                    React.createElement(Trans, { i18nKey: "name" })),
                React.createElement(MyInput, { value: formData.title, onChangeHandler: (value) => onChange('title', value), placeholder: t('enterName'), warnIfContainsInvisibleCharacters: true })),
            React.createElement("div", { className: s.labelContainer },
                React.createElement("label", null, activeAdapter.idInfo.title),
                React.createElement(MyInput, { value: accountId || '', disabled: !!entity, required: !entity, onChangeHandler: (value) => setAccountId(value), warnIfContainsInvisibleCharacters: true }),
                !!activeAdapter.idInfo.description &&
                    React.createElement("p", { className: "ant-form-item-extra" }, activeAdapter.idInfo.description)),
            entity && (React.createElement(FeaturesGate, { features: [MessagingChannelFeature.profileInfoEdit], customAdapter: activeAdapter },
                React.createElement("div", { className: s.labelContainer },
                    React.createElement(Button, { onClick: onEditBusinessProfile, className: classnames(s.channelFormButton, 'big-green-button'), block: true },
                        React.createElement(Icon, { component: EditOutlined }),
                        React.createElement(Trans, { i18nKey: "editBussinesProfile" }))))),
            React.createElement("div", { className: s.labelContainer },
                React.createElement("label", null,
                    React.createElement(Trans, { i18nKey: "channelTheme" })),
                React.createElement(MySelect, { value: formData.themeId, onChange: (themeId) => onChange('themeId', themeId), size: "large" }, channelThemes.map(({ icon, color, id }) => {
                    const isThemeInUse = themesIdsInUse.includes(id);
                    return (React.createElement(Select.Option, { value: id, key: id },
                        React.createElement("img", { src: icon, alt: "icon", className: s.iconSelectOption }),
                        React.createElement("div", { style: {
                                background: isArray(color)
                                    ? `linear-gradient(${color[0]}50, ${color[1]}50)`
                                    : color,
                            }, className: s.colorSelectOption }),
                        isThemeInUse && React.createElement("span", null, "(in use)")));
                }))),
            React.createElement("div", { className: classnames(s.labelContainer, s.checkboxContainer) },
                React.createElement(Checkbox, { checked: formData.botDisable, onChange: (e) => onChange('botDisable', e.target.checked) }, t('disableBot'))),
            React.createElement("div", { className: s.labelContainer },
                React.createElement("label", null,
                    React.createElement(Trans, { i18nKey: "defaultBotLabel" })),
                React.createElement(MySelect, { value: formData.bot, onChange: onBotChange, size: "large", allowClear: true, options: [
                        {
                            label: React.createElement(Trans, { i18nKey: "noBotSelected" }),
                            value: '',
                        },
                        ...bots.map(({ title, identifier }) => ({
                            label: title || identifier,
                            value: identifier,
                        })),
                    ] })),
            entity && (React.createElement(FeaturesGate, { features: [MessagingChannelFeature.webhooksSetAuto], customAdapter: activeAdapter },
                React.createElement("div", { className: s.labelContainer },
                    React.createElement("label", { className: s.labelWebookContainer },
                        React.createElement("span", null,
                            React.createElement(Trans, { i18nKey: "webhook" })),
                        (webhookMessage || webhookError) && (React.createElement("div", { className: classnames(s.webhookStatusContainer, {
                                [s.success]: webhookMessage,
                            }) },
                            React.createElement("div", { className: s.webhookDot }),
                            React.createElement("div", { className: s.webhookStatus },
                                React.createElement(Trans, { i18nKey: webhookMessage }))))),
                    React.createElement("div", { className: s.webhookContainer },
                        React.createElement(Button, { onClick: () => onConnectOrDisconnectWebhook(), className: classnames(s.channelFormButton, 'white-button'), disabled: webhookLoading },
                            React.createElement(Trans, { i18nKey: "connectWebhook" })),
                        React.createElement(Button, { onClick: () => onConnectOrDisconnectWebhook(true), className: classnames(s.channelFormButton, 'white-button'), disabled: webhookLoading },
                            React.createElement(Trans, { i18nKey: "disconnectWebhook" })))))),
            React.createElement("div", { className: classnames(s.labelContainer, s.checkboxContainer) },
                React.createElement(Checkbox, { checked: formData.isDefault, onChange: (e) => onChange('isDefault', e.target.checked) }, t('defaultChannel'))),
            activeAdapter.credentialsSchema && (React.createElement("fieldset", { className: s.credentialsContainer },
                React.createElement("legend", null,
                    React.createElement(Trans, { i18nKey: "channelCredentials" })),
                !!entity &&
                    React.createElement("div", { className: classnames(s.labelContainer, s.checkboxContainer) },
                        React.createElement(Checkbox, { checked: updateCredentials, onChange: (e) => setUpdateCredentials(e.target.checked) }, t('updateChannelCredentials'))),
                (!entity || updateCredentials) && (React.createElement(RJSFForm, { validator: validator, schema: activeAdapter.credentialsSchema, formData: formData.credentials, onChange: (e) => setFormData((formData) => (Object.assign(Object.assign({}, formData), { credentials: e.formData }))), onError: (err) => message.error(err[0].message), ref: formRef }))))))));
};
export default observer(ChannelsForm);
