var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import Icon, { LeftOutlined } from "@ant-design/icons";
import { Button, Drawer, message, Tooltip } from "antd";
import classnames from "classnames";
import { Observer } from "mobx-react";
import React, { Component } from "react";
import { Trans, useTranslation } from "react-i18next";
import { RiMailCloseLine } from "react-icons/ri";
import BlockedChatModal from "../BlockedChat/BlockedChatModal";
import blankProfilePicture from "../../../assets/avatars/blankProfilePicture.png";
import exportLogo from "../../../assets/crmLogos/cloud-link-icon.svg";
import { getChannelSharableLink, getDirection } from "../../../helpers/functions";
import { getFilePreviewUrl } from "../../../store/FilesStore";
import { useStore } from "../../../store/rootStore";
import ChatIdInfo from "../ChatIdInfo";
import ChatTitle from "../ChatTitle";
import SubscribeModal from "./Components/SubscribeModal";
import styles from "./UserInfo.module.scss";
import AssignToModal from "../../MainPage/modals/AssignToModal";
import useResolveChat from "../../../helpers/hooks/useResolveChat";
import { omit } from "lodash";
import Module from "../../MainPage/RightBar/modules";
import { PortalHost, PortalName } from "../../portals";
import { ChatPaneName } from "../chatPaneName";
import DropdownMenu from "./Components/DropdownMenu";
class UserInfo extends Component {
    constructor() {
        super(...arguments);
        Object.defineProperty(this, "state", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: {
                blockChatLoading: false,
                isAssignModalVisible: false,
                showBlockModal: false,
                showSubscribeModal: false,
                showRightModuleDrawer: false,
                imageUrl: '',
            }
        });
        Object.defineProperty(this, "toggleRightModuleDrawer", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (show) => {
                this.setState({ showRightModuleDrawer: show });
            }
        });
        Object.defineProperty(this, "unsubscribeFromTM", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (unsubscribed, silent = false) => __awaiter(this, void 0, void 0, function* () {
                const { activeChat } = this.props;
                yield this.props.store.chatsStore
                    .subscribeUser(activeChat._id, unsubscribed)
                    .then(() => {
                    if (silent)
                        return;
                    message.success(React.createElement(Trans, { i18nKey: unsubscribed ? "successResubscribeMessage" : "successUnsubscribeMessage" }));
                })
                    .catch((error) => {
                    console.error(error);
                });
            })
        });
        Object.defineProperty(this, "setProfilePicture", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: () => {
                var _a;
                const imageUrl = ((_a = this.props.activeChat) === null || _a === void 0 ? void 0 : _a.image)
                    ? getFilePreviewUrl(this.props.activeChat.image)
                    : blankProfilePicture;
                this.setState({ imageUrl });
            }
        });
        Object.defineProperty(this, "blockUser", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: () => __awaiter(this, void 0, void 0, function* () {
                const { activeChat, store } = this.props;
                const unsubscribed = !!activeChat.unsubscribed || !!activeChat.blockTemplates;
                try {
                    this.setState({ blockChatLoading: true });
                    yield store.activeChatStore.blockUnblockChat();
                    if (!unsubscribed) {
                        yield this.unsubscribeFromTM(false, true);
                    }
                }
                catch (err) {
                    console.error('Error blocking/unblocking the user', err);
                }
                finally {
                    this.setState({ blockChatLoading: false });
                }
            })
        });
        Object.defineProperty(this, "unblockUser", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: () => __awaiter(this, void 0, void 0, function* () {
                yield this.props.store.activeChatStore.unblockChat();
            })
        });
        Object.defineProperty(this, "markAsImportant", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: () => __awaiter(this, void 0, void 0, function* () {
                const { activeChat, store } = this.props;
                store.chatsStore.toggleMarkFavorite(activeChat._id, !store.activeChatStore.important);
            })
        });
        Object.defineProperty(this, "onShareChat", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: () => {
                const { t, activeChat } = this.props;
                if (window.isSecureContext) {
                    const url = getChannelSharableLink(activeChat.channelInfo);
                    navigator.clipboard.writeText(url);
                    message.success(t('copiedToClipboard'));
                }
                else {
                    message.error(t('clipboardNotSupported'));
                }
            }
        });
        Object.defineProperty(this, "onShowRightModuleDrawer", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (show) => {
                const { isMobile } = this.props;
                if (isMobile) {
                    this.toggleRightModuleDrawer(show);
                }
            }
        });
        Object.defineProperty(this, "onShowCRM", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: () => {
                this.props.onSwitchPane();
                this.onShowRightModuleDrawer(true);
            }
        });
        Object.defineProperty(this, "onShowFilesMediaLinks", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: () => {
                this.props.onSwitchPane(ChatPaneName.media);
                this.onShowRightModuleDrawer(true);
            }
        });
        Object.defineProperty(this, "onShowSearchInChat", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: () => {
                this.props.onSwitchPane(ChatPaneName.search);
                this.onShowRightModuleDrawer(true);
            }
        });
        Object.defineProperty(this, "onBlockUser", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: () => {
                var _a;
                const { activeChat } = this.props;
                if ((_a = activeChat.blockedChat) === null || _a === void 0 ? void 0 : _a.blocked) {
                    this.unblockUser();
                }
                else {
                    this.setState({ showBlockModal: true });
                }
            }
        });
        Object.defineProperty(this, "onAssignTo", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: () => {
                this.setState({ isAssignModalVisible: true });
            }
        });
    }
    componentDidMount() {
        this.setProfilePicture();
    }
    componentDidUpdate(prevProps) {
        var _a;
        const { activeChat } = this.props;
        if (((activeChat === null || activeChat === void 0 ? void 0 : activeChat.image) && !this.state.imageUrl) ||
            ((activeChat === null || activeChat === void 0 ? void 0 : activeChat._id) !== ((_a = prevProps.activeChat) === null || _a === void 0 ? void 0 : _a._id))) {
            this.setProfilePicture();
        }
    }
    render() {
        const { activeChat, store, isMobile, resolveChatProps } = this.props;
        const { activeChatStore } = store;
        const { showBlockModal, showSubscribeModal, showRightModuleDrawer, imageUrl, isAssignModalVisible } = this.state;
        const { theme } = activeChatStore;
        const unsubscribed = !!activeChat.unsubscribed || !!activeChat.blockTemplates;
        return (React.createElement(Observer, null, () => {
            var _a, _b;
            return (React.createElement(React.Fragment, null,
                React.createElement("div", { className: styles.userInfoData },
                    React.createElement(LeftOutlined, { className: classnames(styles.userInfoBack, {
                            [styles.rtl]: getDirection() === 'rtl',
                        }), onClick: () => activeChatStore.removeActiveChat() }),
                    React.createElement("div", { className: styles.photoWrapper },
                        React.createElement("img", { src: imageUrl || blankProfilePicture, className: styles.userInfoAvatar }),
                        React.createElement("img", { className: styles.userInfoAvatarSmall, src: theme.icon })),
                    React.createElement("div", { className: classnames(styles.userInfoText, {
                            [styles.rtl]: getDirection() === 'rtl',
                        }) },
                        React.createElement("div", { className: styles.userInfoName },
                            unsubscribed && (React.createElement(Tooltip, { overlayStyle: { maxWidth: 300 }, placement: "right", title: React.createElement("div", { className: styles.unsubscribedTooltipContainer },
                                    React.createElement("div", { className: styles.unsubscribedTooltipInfo },
                                        React.createElement(Trans, { i18nKey: "unsubscribedFromTemplateMessages" })),
                                    React.createElement("div", { className: styles.unsubscribedTooltipAction, onClick: () => this.setState({ showSubscribeModal: true }) },
                                        React.createElement(Trans, { i18nKey: "resubscribe" }))) },
                                React.createElement(Icon, { className: classnames(styles.menuIcon, styles.unsubscribedIcon), component: RiMailCloseLine }))),
                            React.createElement(ChatTitle, { chat: activeChat, showId: "none" })),
                        React.createElement(ChatIdInfo, { chat: activeChat }))),
                React.createElement("div", { className: styles.actionBar },
                    React.createElement(DropdownMenu, { blocked: ((_a = activeChat.blockedChat) === null || _a === void 0 ? void 0 : _a.blocked) || false, important: activeChatStore.important, unsubscribed: !!activeChat.unsubscribed || !!activeChat.blockTemplates, markAsImportant: this.markAsImportant, showSubscribeModal: () => this.setState({ showSubscribeModal: true }), onBlockUserClick: this.onBlockUser, onShowFilesMediaLinks: this.onShowFilesMediaLinks, onSearchInChat: this.onShowSearchInChat, onShareChatClick: this.onShareChat, isMobile: isMobile, mobileProps: {
                            sessionIsOver: activeChatStore.sessionIsOver,
                            onAssignToClick: this.onAssignTo,
                            resolveChatProps: omit(resolveChatProps, 'resolveChatModal'),
                        } }),
                    isMobile && (React.createElement(Button, { onClick: this.onShowCRM, className: styles.icon },
                        React.createElement("img", { src: exportLogo, alt: "crm-logo" })))),
                React.createElement(BlockedChatModal, { visible: showBlockModal, onCancel: () => this.setState({ showBlockModal: false }), onSubmit: this.blockUser, name: activeChat.title, phone: activeChat.clientPhoneE164, block: !((_b = activeChat.blockedChat) === null || _b === void 0 ? void 0 : _b.blocked) }),
                React.createElement(Drawer, { width: 350, placement: "right", closable: false, className: styles.rightModuleDrawer, open: showRightModuleDrawer, onClose: () => this.toggleRightModuleDrawer(false) },
                    React.createElement(PortalHost, { name: PortalName.mobileDrawer, className: 'mobile-drawer' },
                        React.createElement(Module, null))),
                React.createElement(SubscribeModal, { onCancel: () => this.setState({ showSubscribeModal: false }), onOk: () => {
                        this.unsubscribeFromTM(unsubscribed).catch((e) => console.error('Error unsubscribing', e));
                        this.setState({ showSubscribeModal: false });
                    }, visible: showSubscribeModal, activeChat: activeChat }),
                React.createElement(AssignToModal, { isAssignModalVisible: isAssignModalVisible, toggleModal: (visible) => this.setState({ isAssignModalVisible: visible }), assignFunction: store.chatsStore.assignChat }),
                resolveChatProps.resolveChatModal));
        }));
    }
}
export default (props) => {
    const store = useStore();
    const { t } = useTranslation();
    const resolveChatProps = __rest(useResolveChat(), []);
    return React.createElement(UserInfo, Object.assign({}, props, { store, t, resolveChatProps }));
};
