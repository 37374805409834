var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import classNames from 'classnames';
import { LoadingOutlined, SearchOutlined, WarningOutlined } from '@ant-design/icons';
import { Trans } from 'react-i18next';
import { Input as AntdInput, Tooltip } from 'antd';
import { getDirection } from '../../../../helpers';
import useShouldShowWarningIfContainsInvisibleCharacters from './useShouldShowWarningIfContainsInvisibleCharacters';
import './MyInput.scss';
const InputWithLabel = ({ labelKey, children }) => {
    return (React.createElement(React.Fragment, null,
        labelKey && (React.createElement("label", { className: "myinput-label", htmlFor: labelKey },
            React.createElement(Trans, { i18nKey: labelKey }))),
        children));
};
const Input = (_a) => {
    var { className, size = 'middle', fullWidth = true, bordered = true, onChangeHandler, loading, allowClear, forwardRef, labelKey, type, value, warnIfContainsInvisibleCharacters } = _a, rest = __rest(_a, ["className", "size", "fullWidth", "bordered", "onChangeHandler", "loading", "allowClear", "forwardRef", "labelKey", "type", "value", "warnIfContainsInvisibleCharacters"]);
    const showWarningIfContainsInvisibleCharacters = useShouldShowWarningIfContainsInvisibleCharacters(value, warnIfContainsInvisibleCharacters);
    return (React.createElement(InputWithLabel, { labelKey: labelKey },
        React.createElement(AntdInput, Object.assign({ className: classNames('myinput-wrapper', className, {
                rtl: getDirection() === 'rtl',
                'full-width': fullWidth,
                large: size === 'large',
                middle: size === 'middle',
            }), dir: getDirection(), ref: forwardRef, size: size, type: type, value: value, onChange: (e) => onChangeHandler === null || onChangeHandler === void 0 ? void 0 : onChangeHandler(e.target.value), status: showWarningIfContainsInvisibleCharacters ? 'warning' : undefined, prefix: type === 'search' ? (React.createElement(SearchOutlined, { className: "myinput-search" })) : showWarningIfContainsInvisibleCharacters ? (React.createElement(Tooltip, { title: React.createElement(Trans, { i18nKey: "includesInvisibleCharacters" }) },
                React.createElement(WarningOutlined, null))) : (
            // https://ant.design/components/input#why-input-lose-focus-when-change-prefixsuffixshowcount
            React.createElement("span", null)), suffix: loading ? React.createElement(LoadingOutlined, { className: "myinput-loading" }) : null, allowClear: loading ? false : allowClear, bordered: bordered }, rest))));
};
const TextArea = (_a) => {
    var { className, fullWidth, size, forwardRef, onChange, bordered = false, labelKey, value } = _a, rest = __rest(_a, ["className", "fullWidth", "size", "forwardRef", "onChange", "bordered", "labelKey", "value"]);
    return (React.createElement(InputWithLabel, { labelKey: labelKey },
        React.createElement(AntdInput.TextArea, Object.assign({ className: classNames('myinput-textarea-wrapper', className, {
                rtl: getDirection() === 'rtl',
                'full-width': fullWidth,
            }), dir: getDirection(), ref: forwardRef, size: size, bordered: bordered, onChange: (e) => onChange === null || onChange === void 0 ? void 0 : onChange(e.target.value, e.target.selectionStart, e.target.selectionEnd) }, rest))));
};
const Password = (_a) => {
    var { className, size, forwardRef, onChangeHandler, labelKey, value, warnIfContainsInvisibleCharacters } = _a, rest = __rest(_a, ["className", "size", "forwardRef", "onChangeHandler", "labelKey", "value", "warnIfContainsInvisibleCharacters"]);
    const showWarningIfContainsInvisibleCharacters = useShouldShowWarningIfContainsInvisibleCharacters(value, warnIfContainsInvisibleCharacters);
    return (React.createElement(InputWithLabel, { labelKey: labelKey },
        React.createElement(AntdInput.Password, Object.assign({ className: classNames('myinput-wrapper', className, {
                rtl: getDirection() === 'rtl',
                large: size === 'large',
                middle: size === 'middle',
            }), value: value, onChange: (e) => onChangeHandler === null || onChangeHandler === void 0 ? void 0 : onChangeHandler(e.target.value), dir: getDirection(), ref: forwardRef, size: size, status: showWarningIfContainsInvisibleCharacters ? 'warning' : undefined, prefix: showWarningIfContainsInvisibleCharacters ? (React.createElement(Tooltip, { title: React.createElement(Trans, { i18nKey: "includesInvisibleCharacters" }) },
                React.createElement(WarningOutlined, null))) : (React.createElement("span", null)) }, rest))));
};
export default Object.assign(Input, { TextArea, Password, Text: AntdInput });
